import styled from "styled-components";
import { theme } from "../../constants/Theme";

export const TextInput = styled.input<{
  width?: string;
  height?: string;
}>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  border: 1px solid ${theme.colors.input.border};
  border-radius: 6px;
  padding: 0 15px;
  font-size: 16px;
  font-family: nsRegular;
  background-color: ${theme.colors.input.background};
  color: ${theme.colors.text.primary};
  &:focus {
    outline: solid ${theme.colors.text.primary};
  }
  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${theme.colors.text.primary};
  }
  &:-ms-input-placeholder {
    color: ${theme.colors.text.primary};
  }
  box-sizing: border-box;
`;
