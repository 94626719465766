import {
  fadeIn,
  fadeOut,
  toastSlideIn,
  toastSlideOut,
  sheetSlideLeftIn,
  sheetSlideLeftOut,
  sheetSlideRightIn,
  sheetSlideRightOut,
  sheetSlideTopIn,
  sheetSlideTopOut,
  sheetSlideBottomIn,
  sheetSlideBottomOut,
} from "./Animations";

export const colors = {
  White: "#ffffff", //white 1
  Steel: "#e4e4e7", //white2
  Marble: "#a1a1aa", //white 3

  Space: "#09090b", //black 1
  Gunmetal: "#27272a", //black 2
  Slate: "#71717a", //black 3

  TychoTidBit: "#673ab7",
  TychoSandTidBit: "#826d8c",
  TychoTadBit: "#9575cd",
  TychoHealth: "#4169e1",
  TychoWellness: "#9cafe6",
  TychoChampagne: "#FCD0A1",
  TychoIce: "#4B88A2",
  TychoFoot: "#298213",
  TychoForward: "#9cc691",
  TychoMint: "#D7FDEC",
  TychoTach: "#96b79f",
  TychoMidnight: "#204B57",
  TychoJade: "#1f7a6a",
  TychoDusk: "#8cabb4",
  TychoQuiet: "#a2bfc7",
  TychoSaffron: "#EAC435",

  TychoOrange: "#ff6c0e",
  TychoPeach: "#ffab7d",
  TychoOrangeOld: "#F7A70C",
  TychoPeachOld: "#f5daa6",
  Saffron: "#FCCD6E",

  YellowGreenBg: "#bed2904D",
  YellowGreen: "#9BC53D",

  BlueBg: "#96c8d44D",
  Blue: "#559CAD",

  YellowBg: "#f6df874D",
  Yellow: "#ceaa24",

  OrangeBg: "#ffab7d4d",
  Orange: "#ff6c0e",

  RedLight: "#C26C75",
  RedDark: "#C24034",
};

const shadowValues = {
  small: `0 1px 2px 0 rgba(0, 0, 0, 0.05)`,
  medium: `0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  large: `0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
};

const animations = {
  fadeIn: fadeIn,
  fadeOut: fadeOut,
  toastSlideIn: toastSlideIn,
  toastSlideOut: toastSlideOut,
  sheet: {
    top: {
      enter: sheetSlideTopIn,
      leave: sheetSlideTopOut,
    },
    right: {
      enter: sheetSlideRightIn,
      leave: sheetSlideRightOut,
    },
    bottom: {
      enter: sheetSlideBottomIn,
      leave: sheetSlideBottomOut,
    },
    left: {
      enter: sheetSlideLeftIn,
      leave: sheetSlideLeftOut,
    },
  },
};

const themeLight = {
  colors: {
    layout: {
      background: colors.White,
      border: colors.Steel,
      accent: colors.TychoOrange,
    },
    tabs: {
      selected: colors.White,
      background: colors.Steel,
      hover: colors.Marble,
    },
    container: {
      background: colors.White,
      border: colors.Steel,
      accent: colors.TychoOrange,
    },
    calendar: {
      background: colors.White,
      border: colors.Steel,
      selected: colors.Space,
      hover: colors.Steel,
    },
    dropdown: {
      background: colors.White,
      border: colors.Steel,
      selected: colors.Space,
      hover: colors.Steel,
    },
    input: {
      background: colors.White,
      border: colors.Steel,
      focus: colors.Space,
    },
    button: {
      primary: {
        background: colors.Space,
        border: colors.Space,
        hover: colors.Gunmetal,
      },
      secondary: {
        background: colors.Steel,
        border: colors.Steel,
        hover: colors.Steel,
      },
      outline: {
        background: colors.White,
        border: colors.Steel,
        hover: colors.Steel,
      },
      ghost: {
        background: colors.White,
        border: colors.White,
        hover: colors.Steel,
      },
    },
    select: {
      background: colors.White,
      secondary: colors.Steel,
      border: colors.Steel,
      highlight: colors.Steel,
      disabled: colors.Marble,
    },
    switch: {
      background: colors.Steel,
      checked: colors.Space,
      thumb: colors.White,
    },
    nav: {
      background: colors.White,
      border: colors.Steel,
      selected: colors.TychoOrange,
      subdued: colors.Marble,
      highlight: colors.TychoOrange,
    },
    text: {
      primary: colors.Space,
      secondary: colors.Slate,
      subdued: colors.Steel,
      contrast: colors.White,
      contrastSecondary: colors.Marble,
      error: colors.RedDark,
    },
    shadow: shadowValues,
  },
  animation: animations,
};

const themeDark = {
  colors: {
    layout: {
      background: colors.Space,
      border: colors.Gunmetal,
      accent: colors.TychoSaffron,
    },
    tabs: {
      selected: colors.Space,
      background: colors.Gunmetal,
      hover: colors.Slate,
    },
    container: {
      background: colors.Space,
      border: colors.Gunmetal,
      accent: colors.TychoSaffron,
    },
    calendar: {
      background: colors.Space,
      border: colors.Gunmetal,
      selected: colors.White,
      hover: colors.Gunmetal,
    },
    dropdown: {
      background: colors.Space,
      border: colors.Gunmetal,
      selected: colors.White,
      hover: colors.Gunmetal,
    },
    input: {
      background: colors.Space,
      border: colors.Gunmetal,
      focus: colors.White,
    },
    button: {
      primary: {
        background: colors.White,
        border: colors.White,
        hover: colors.Steel,
      },
      secondary: {
        background: colors.Gunmetal,
        border: colors.Gunmetal,
        hover: colors.Gunmetal,
      },
      outline: {
        background: colors.Space,
        border: colors.Gunmetal,
        hover: colors.Gunmetal,
      },
      ghost: {
        background: colors.Space,
        border: colors.Space,
        hover: colors.Gunmetal,
      },
    },
    select: {
      background: colors.Space,
      secondary: colors.Gunmetal,
      border: colors.Gunmetal,
      highlight: colors.Gunmetal,
      disabled: colors.Marble,
    },
    switch: {
      background: colors.Gunmetal,
      checked: colors.White,
      thumb: colors.Space,
    },
    nav: {
      background: colors.Space,
      border: colors.Gunmetal,
      selected: colors.TychoSaffron,
      subdued: colors.Marble,
      highlight: colors.TychoSaffron,
    },
    text: {
      primary: colors.White,
      secondary: colors.Marble,
      subdued: colors.Gunmetal,
      contrast: colors.Space,
      contrastSecondary: colors.Marble,
      error: colors.RedLight,
    },
    shadow: shadowValues,
  },
  animation: animations,
};

export const theme = window.matchMedia("(prefers-color-scheme: dark)").matches
  ? themeDark
  : themeLight;
