import styled from "styled-components";
import { theme } from "../../constants/Theme";

export const Button = styled.button<{
  type: "primary" | "secondary" | "outline" | "ghost";
  width?: string;
  height?: string;
  padding?: string;
}>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  border: 1px solid ${(props) => theme.colors.button[props.type].border};
  border-radius: 6px;
  padding: ${(props) => (props.padding ? props.padding : "0 15px")};
  font-size: 14px;
  font-family: nsBold;
  background-color: ${(props) => theme.colors.button[props.type].background};
  color: ${(props) =>
    props.type === "primary"
      ? theme.colors.text.contrast
      : theme.colors.text.primary};
  cursor: pointer;
  &:hover {
    transition: 0.1s;
    background: ${(props) => theme.colors.button[props.type].hover};
  }
`;
