import { keyframes } from "styled-components";

export const toastSlideIn = keyframes`
  0% { right: 0em; }
  100% { right: 3em;}
`;

export const toastSlideOut = keyframes`
  0% { right: 3em; }
  100% { opacity: 0; right: 0em;}
`;

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1;}
`;

export const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0;}
`;

export const sheetSlideTopIn = keyframes`
  0% { top: -100%; }
  100% { top: 0%;}
`;

export const sheetSlideTopOut = keyframes`
  0% { top: 0%; }
  100% { top: -100%;}
`;

export const sheetSlideRightIn = keyframes`
  0% { right: -100%; }
  100% { right: 0%;}
`;

export const sheetSlideRightOut = keyframes`
  0% { right: 0%; }
  100% { right: -100%;}
`;

export const sheetSlideBottomIn = keyframes`
  0% { bottom: -100%; }
  100% { bottom: 0%;}
`;

export const sheetSlideBottomOut = keyframes`
  0% { bottom: 0%; }
  100% { bottom: -100%;}
`;

export const sheetSlideLeftIn = keyframes`
  0% { left: -100%; }
  100% { left: 0%;}
`;

export const sheetSlideLeftOut = keyframes`
  0% { left: 0%; }
  100% { left: -100%;}
`;
