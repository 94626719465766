import styled from "styled-components";
import { theme } from "../../constants/Theme";

export const Page = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${theme.colors.layout.background};
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: left;
  overflow: hidden;
`;
