import styled from "styled-components";
import { theme } from "../../constants/Theme";

interface FormFieldProps {
  children: any;
  label: string;
}

export const FormLayout = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const FormFieldLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FormFieldLabel = styled.div`
  font-size: 12px;
  font-family: nsSemiBold;
  color: ${theme.colors.text.secondary};
`;

export const FormField = ({ children, label }: FormFieldProps) => {
  return (
    <FormFieldLayout>
      <FormFieldLabel>{label}</FormFieldLabel>
      {children}
    </FormFieldLayout>
  );
};
