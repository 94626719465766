import styled from "styled-components";
import { theme } from "../../constants/Theme";

export const Content = styled.div`
  padding: 0px;
  height: 100%;
  width: 100%;
  background: ${theme.colors.container.background};
  border-radius: 0px;
  border: 1px solid ${theme.colors.container.border};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ContentBanner = styled.div<{ padding?: string }>`
  max-width: 100%;
  background: ${theme.colors.container.background};
  color: ${theme.colors.text.primary};
  border-bottom: 1px solid ${theme.colors.container.border};
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: ${(props) => props.padding ?? "30px 40px"};
`;

export const ContentMain = styled.div<{ padding?: string }>`
  background: ${theme.colors.container.background};
  color: ${theme.colors.text.primary};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: ${(props) => props.padding ?? "40px"};
`;

export const Flex = styled.div<{
  direction?: "row" | "column";
  gap?: string;
  width?: string;
  justify?:
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "left"
    | "right"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch";
  align?: "center" | "left" | "right";
}>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  align-items: ${(props) => (props.align ? props.align : "right")};
  gap: ${(props) => (props.gap ? `${props.gap}` : "0px")};
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  justify-content: ${(props) => props.justify ?? "normal"};
`;

export const Grid = styled.div<{
  gap?: string;
  width?: string;
}>`
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  display: grid;
  gap: ${(props) => (props.gap ? `${props.gap}` : "0px")};
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
`;
