import styled from "styled-components";
import { theme } from "../../constants/Theme";

export enum FontWeight {
  "light" = "nsLight",
  "regular" = "nsRegular",
  "semibold" = "nsSemiBold",
  "bold" = "nsBold",
}

export enum FontHeaderWeight {
  "light" = "nsHeaderLight",
  "regular" = "nsHeaderRegular",
  "semibold" = "nsSemiBold",
  "bold" = "nsHeaderBold",
}

enum FontSize {
  "small" = "12px",
  "medium" = "14px",
  "large" = "16px",
  "xLarge" = "18px",
}

enum FontHeaderSize {
  "small" = "16px",
  "medium" = "20px",
  "large" = "24px",
  "xLarge" = "30px",
}

export interface TextProps {
  size?: "small" | "medium" | "large" | "xLarge";
  weight?: "light" | "regular" | "semibold" | "bold";
  color?:
    | "primary"
    | "secondary"
    | "subdued"
    | "contrast"
    | "contrastSecondary"
    | "error";
}

export const Text = styled.div<TextProps>`
  font-family: ${(props) =>
    props.weight ? FontWeight[props.weight] : FontWeight.regular};
  font-size: ${(props) => (props.size ? FontSize[props.size] : "16px")};
  color: ${(props) =>
    props.color ? theme.colors.text[props.color] : theme.colors.text.primary};
`;

export const Heading = styled.div<TextProps>`
  margin: 0px;
  font-family: ${(props) =>
    props.weight ? FontHeaderWeight[props.weight] : FontHeaderWeight.bold};
  font-size: ${(props) => (props.size ? FontHeaderSize[props.size] : "24px")};
  color: ${(props) =>
    props.color ? theme.colors.text[props.color] : theme.colors.text.primary};
`;
