import { TextInput } from "../../components/input/TextInput";
import {
  Content,
  ContentBanner,
  ContentMain,
  Flex,
} from "../../components/layout/Content";
import { Page } from "../../components/layout/Page";
import { Heading, Text } from "../../components/typography/Typography";
import { theme } from "../../constants/Theme";
import { Button } from "../../components/button/Button";
import { ReactComponent as TychoMoon } from "../../resources/images/TychoMoon.svg";
import { Facebook, Linkedin } from "lucide-react";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FormField } from "../../components/forms/Forms";
import axios from "axios";

interface LandingMailingListFormValues {
  email: string;
}

const mailingListUrl = "https://vfranco.me/api/tycholabs/mailing-list";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const Landing = () => {
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LandingMailingListFormValues>({
    defaultValues: {
      email: "",
    },
  });

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [submittedSuccess, setSubmittedSuccess] = useState<boolean>(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleSubmitButtonClick = () => {
    axios
      .post(mailingListUrl, { email: getValues("email") })
      .then(() => {
        setSubmittedSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Page>
      <Content>
        <ContentBanner padding={windowSize[0] <= 768 ? "16px" : "30px"}>
          <Flex direction="row" justify="space-between" align="center">
            <Heading>GrandStaff</Heading>
            <Flex direction="row" gap="8px" justify="right">
              <Button type="outline" width="40px" padding="0px">
                <Facebook
                  width="26px"
                  height="26px"
                  color={theme.colors.text.primary}
                />
              </Button>
              <Button type="outline" width="40px" padding="0px">
                <Linkedin
                  width="26px"
                  height="26px"
                  color={theme.colors.text.primary}
                />
              </Button>
            </Flex>
          </Flex>
        </ContentBanner>
        <ContentMain padding={windowSize[0] <= 768 ? "16px" : "30px"}>
          <Flex direction="column" gap="24px" justify="center" align="center">
            {windowSize[0] <= 768 ? (
              <TychoMoon height="200px" width="200px" />
            ) : (
              <TychoMoon height="400px" width="400px" />
            )}

            <Text size="xLarge" weight="semibold">
              Enter your email to join our mailing list.
            </Text>
            <form
              noValidate
              onSubmit={handleSubmit(() => {
                try {
                  handleSubmitButtonClick();
                } catch (e) {
                  console.log(e);
                }
              })}
            >
              <Flex direction="row" gap="8px" align="left">
                <FormField label="Email">
                  <Flex direction="row" gap="8px">
                    <TextInput
                      required
                      {...register("email", {
                        required: "Please enter an email.",
                        pattern: emailRegex,
                      })}
                      width={windowSize[0] <= 768 ? "" : "400px"}
                      placeholder="Enter email"
                      type="email"
                      value={watch("email")}
                    />
                    <Button type="primary" width="80px">
                      Register
                    </Button>
                  </Flex>

                  {errors?.email && (
                    <Text color="error">Please enter a valid email.</Text>
                  )}
                </FormField>
              </Flex>
            </form>
            {submittedSuccess && (
              <Text size="xLarge" weight="bold">
                Thanks for registering! Keep an eye on your inbox, more to come
                soon!
              </Text>
            )}
          </Flex>
        </ContentMain>
      </Content>
    </Page>
  );
};

export default Landing;
